
import Vue from 'vue';
import { mapActions } from 'vuex';
import UserFeed from '@/components/Layouts/UserFeed.vue';
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: 'Home',
  props: {
    searchText: String,
    tab: String,
  },
  components: {
    UserFeed,
  },
  methods: {
    ...mapActions('oidcStore', ['signOutOidcSilent', 'signoutCallback']),    
    signUserOut: function () {
      this.signOutOidcSilent().then(res => {
        this.$store.commit('loadUserInfo', null); 
      })
    }
  },   
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
