
import Vue from "vue";
import pedigreeService from "@/services/admin/pedigreeService";
import { UserInfo } from "@/types/User";
import ReportViewer from "@/components/Admin/Reports/ReportViewer.vue";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";
import { Messages } from "@/constants";

import { getPedigreePrintTypesList } from "@/services/lookupsService";
    
    export default Vue.extend({
      name: "PedigreeOrdersList",
      components: {
        Datepicker,
        PaginationSelect,
        ReportViewer
      },
      data: function() {
        return {
          selectedPedigreeOrders: [] as any[],
          allSelected: false,
          title: 'Pedigree Orders',
          loading: false,
          isError: false,
          errorMessage: "",
          Report_Name: '',
          Report_URL: '',
          showReportDialog: false,
          paginatedResponse: {} as PaginatedResponse<object>,
          searchParams: {
            dogId: "",
            dogName: "", 
            personName: "", 
            printType: 1,
            createdDateFrom: "",
            createdDateTo: "",
            printedDateFrom: "",
            printedDateTo: "",
            printed: false as boolean | null,
          },    
          options: { itemsPerPage: 100, page: 1 } as PaginationOptions,   
          headers: [
            { text: "Dog ID#", align: "left", value: "dogId", width: "75px",  sortable: false },
            { text: "Dog Name", align: "left", value: "dogName", width: "150px",  sortable: false },
            { text: "Person Name", align: "left", value: "personName", width: "100px",  sortable: false },
            { text: "Print Type", align: "left", value: "printType", width: "50px",  sortable: false },
            { text: "Ordered At", align: "left", value: "createdAt", width: "100px",  sortable: false },
            { text: "Printed At", align: "left", value: "printedAt", width: "100px",  sortable: false },
            { text: "", align: "left", value: "actionButton", width: "100px", sortable: false },
            { text: "Submitted By", align: "right", value: "submittedByAdminName", width: "50px", sortable: false, visible: false },
            { text: "Notes", align: "right", value: "adminNotes", width: "50px", sortable: false },
          ],
          printTypes: [] as any[],          
          currentPrintedCheckboxIdx: 0,
        };
      },
      created: function () {
        this.printTypes = getPedigreePrintTypesList();
        if(this.userInfo){
          this.loadPedigreeOrders();
        }
      },
      computed: {
        userInfo(): UserInfo {
          return this.$store.state.userInfo;
        },
      },
      watch: {      
        userInfo: function (newVal: object) { 
          this.loadPedigreeOrders();
        },
        allSelected: function (newValue) {
            if(newValue){
                this.selectedPedigreeOrders = this.paginatedResponse.data;
            } else {
                this.selectedPedigreeOrders = [];
                
            }
        },
      },
      methods: {
        toggleCheckbox: function () {
          var states = [false, true, null, ];
          var next = ++this.currentPrintedCheckboxIdx % states.length;
          this.currentPrintedCheckboxIdx = next;
          this.searchParams.printed = states[next];
        },
        loadPedigreeOrders: async function()  { 
          this.loading = true; 
          const { page, itemsPerPage } = this.options;
    
          try {
            const response = await pedigreeService.getPedigreeOrdersList(
              { ...this.options,
                page: (page ?? 1) - 1,
                itemsPerPage: itemsPerPage ?? 10 }
              ,
              { ...this.searchParams }
            );
    
              this.loading = false;
              this.paginatedResponse = response;
            } catch (ex: any) {    
              this.loading = false;
              
              const descriptiveMessage = ex.response.data.errors
                ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
                : null
    
              this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
          }
        },
        showPedigreeReport: function (dogIds: string[]){
          this.Report_Name = "Asca.Api.Reports.rpt3240_Dog_Pedigree_Report_New2.rdlx";
          this.Report_URL = `/reports/pedigrees?dogIds=${dogIds.join('&dogIds=')}`;
          this.showReportDialog = true;
        },        
        showPrintLabelDialog: function (personIds: number[]) {
            this.Report_Name = "Asca.Api.Reports.rpt1210_Person_Label.rdlx";
            this.Report_URL = `/reports/contacts?personIds=${personIds.join('&personIds=')}`;
            this.showReportDialog = true;
        },
        updateDates: function (val: any, key: string) {
          (this as any).searchParams[key] = val.date;
        },
        updateDateSearch: async function(val: any, key: string) {
          await this.updateDates(val, key);
          this.loadPedigreeOrders();
        },
        clearAllFilters: function () {
          this.options.page = 1;
          this.options.itemsPerPage = 100;
          this.searchParams = {
            dogId: "",
            dogName: "",  
            personName: "", 
            printType: 1,
            createdDateFrom: "",
            createdDateTo: "",
            printedDateFrom: "",
            printedDateTo: "",
            printed: null
          };
        },
        addNewPedigreeOrder: function() {

        },        
        printSelectedPedigrees(){
          var pedigreesDogsIds = this.selectedPedigreeOrders.map(order => order.dogId);
          this.showPedigreeReport(pedigreesDogsIds);
        },       
        printSelectedLabels(){
          var pedigreesPersonIds = this.selectedPedigreeOrders.map(order => order.personId);
          this.showPrintLabelDialog(pedigreesPersonIds);
        },
        markSelectedPrinted: function(){
          var pedigreesOrdersIds = this.selectedPedigreeOrders.map(order => order.id);
          this.markPrinted(pedigreesOrdersIds);
        },
        markPrinted: function(ids: number[]){
          this.errorMessage = "";
          this.loading = true; 
          pedigreeService.markPedigreeOrdersPrinted(ids)
          .then((res) => {
              if (res.success) {
                this.loadPedigreeOrders();
              } else {
                this.errorMessage = Messages.SaveError + " " + res.messages.join(", ");
              }
          })
          .catch((e) => {
            this.errorMessage = Messages.SaveError;
          })
          .finally(() => this.loading = false);

        }
      }
    });
    