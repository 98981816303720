
  import Vue from "vue";
  import pedigreeService from "@/services/portal/pedigreeService";
  import { UserInfo } from "@/types/User";
  import ReportViewer from "@/components/Admin/Reports/ReportViewer.vue";
  import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
  
  export default Vue.extend({
    name: "MyPedigrees",
    components: {
        ReportViewer
    },
    data: function() {
      return {
        loading: false,
        isError: false,
        errorMessage: "",
        Report_Name: '',
        Report_URL: '',
        showReportDialog: false,
        paginatedResponse: {} as PaginatedResponse<object>,
        searchParams: {
          dogId: "",
          dogName: "",          
        },
        options: { itemsPerPage: -1 } as PaginationOptions,   
        headers: [
          { text: "Dog ID#", align: "left", value: "dogId", width: "100px", sortable: false },
          { text: "Dog Name", align: "left", value: "dogName", width: "200px", sortable: false },
          { text: "", align: "left", value: "actionButton", width: "100px", sortable: false },
        ],
      };
    },
    created: function () {
      this.loadMyPedigrees();
    },
    mounted: function () {
      this.loadMyPedigrees();
    },
    computed: {
      userInfo(): UserInfo {
        return this.$store.state.userInfo;
      },
    },
    watch: {      
      userInfo: function (newVal: object) { 
        this.loadMyPedigrees();
      },
    },
    methods: {
      loadMyPedigrees: async function()  {
        this.loading = true;

      const { page, itemsPerPage } = this.options;
      try {
        const response = await pedigreeService.listMyPedigrees(
          this.userInfo.personId,
          { ...this.options,
            page: (page ?? 1) - 1,
            itemsPerPage: itemsPerPage ?? 10 }
          ,
          { ...this.searchParams, personId: this.userInfo.personId }
        );

          this.loading = false;
          this.paginatedResponse = response;
        } catch (ex: any) {
          console.log(ex.response.data.errors)

          this.loading = false;
          this.isError = true;
          
          const descriptiveMessage = ex.response.data.errors
            ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
            : null

          this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
        } finally{
          this.loading = false;
        }
      },
      showPedigreeReport: function (dogId: any){
        this.Report_Name = "Asca.Api.Reports.rpt3240_Dog_Pedigree_Report_New2.rdlx";
        this.Report_URL = `/reports/pedigree/${encodeURIComponent(dogId.trim())}`;
        this.showReportDialog = true;
      },
    }
  });
  