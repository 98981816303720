
import Vue from 'vue';
import MenuLinkSummary from './MenuLinkSummary.vue';
import FilterPills from '../Layouts/FilterPills.vue';

import { UserInfo } from "@/types/User";
import { MenuLinkSummaryVM, MenuLinkVM } from '../../services/viewModels';

import canView from "@/helpers/can-view-summary-item";

export default Vue.extend({
  name: 'Sidebar',
  props: {
  },
  data: function() {
    return {
      personId: null,
      adminMenu: [
        { name: 'My account', active: true },
        { name: 'Administrator', active: false }
      ],
      adminTasksSummaryMenu: Array<MenuLinkSummaryVM>(),
      personTasksSummaryMenu: Array<MenuLinkSummaryVM>(),
      summaries: Array<MenuLinkSummaryVM>()
    };
  },
  components: {
    MenuLinkSummary,
    FilterPills
  },
  methods: {
    adminViewMenu: function(index: number) {
      if(index === 0) {
        this.adminMenu[0].active = true;
        this.adminMenu[1].active = false;
        this.$store.commit('showAdminPersonalAccount', { showAdminPersonalAccount: true });        
      } else {
        this.adminMenu[0].active = false;
        this.adminMenu[1].active = true;
        this.$store.commit('showAdminPersonalAccount', { showAdminPersonalAccount: false });
      }
    },
    canViewSummaryItem: function (summaryItem: MenuLinkSummaryVM | MenuLinkVM): boolean {
      return canView(
        this.userInfo.memberType,
        this.userInfo.hasNeverBeenMember,
        summaryItem.forMembers,
      );
    }
  },
  created: function () {
      this.adminTasksSummaryMenu = this.adminTasksSummary;
      this.personTasksSummaryMenu = this.personTasksSummary;
      this.summaries = this.personTasksSummaryMenu;

      this.adminMenu = [
        { name: 'My account', active: this.showAdminPersonalAccount },
        { name: 'Administrator', active: !this.showAdminPersonalAccount }
      ];

      if(!!this.userInfo && this.userInfo.isAdmin && !this.showAdminPersonalAccount) {
        this.summaries = this.adminTasksSummaryMenu;
      } else {
        this.summaries = this.personTasksSummaryMenu;
      }
  },
  watch: {
    userInfo: function(newVal: UserInfo, oldVal: UserInfo) {  
      console.log('userInfo ' + newVal)
      if(newVal === oldVal) return;
      
      if(newVal.isAdmin && !this.showAdminPersonalAccount) {
        this.summaries = this.adminTasksSummaryMenu;
      } else {
        this.summaries = this.personTasksSummaryMenu;
      }
    },
    showAdminPersonalAccount: function(newVal: boolean, oldVal: boolean) {
      if(newVal === oldVal) return;   

      if(!!this.userInfo && this.userInfo.isAdmin && newVal === false) {
        this.summaries = this.adminTasksSummaryMenu;
      } else {
        this.summaries = this.personTasksSummaryMenu;
      }
    },
    "adminTasksSummary": function(newVal: MenuLinkSummaryVM[], oldVal: MenuLinkSummaryVM[]) {
      if(newVal === oldVal) return;

      this.$data.adminTasksSummaryMenu = newVal;
      if(!!this.userInfo && this.userInfo.isAdmin && !this.showAdminPersonalAccount) {
        this.$data.summaries = newVal;
      }
    },
    "personTasksSummary": function(newVal: MenuLinkSummaryVM[], oldVal: MenuLinkSummaryVM[]) {
      if(newVal === oldVal) return;

      let newMenu = newVal;

      if (!!this.$store.state.userInfo && !!newVal) {
        newMenu = (this.$store.state.personTasksSummary as Array<MenuLinkSummaryVM>)
          .filter(summary => this.canViewSummaryItem(summary))
          .map(summary => ({
            ...summary,
            items: summary.items.filter(item => this.canViewSummaryItem(item))
          }));
      }

      this.$data.personTasksSummaryMenu = newMenu;

      if(!(!!this.userInfo && this.userInfo.isAdmin && !this.showAdminPersonalAccount)) {
        this.$data.summaries = newMenu;
      }
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    showAdminPersonalAccount(): boolean {
      return this.$store.state.showAdminPersonalAccount;
    },
    personTasksSummary():  Array<MenuLinkSummaryVM> {      
      return this.$store.state.personTasksSummary;
    },
    adminTasksSummary():  Array<MenuLinkSummaryVM> {
      return this.$store.state.adminTasksSummary;
    }
  },
});
