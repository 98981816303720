
import Vue from 'vue';
import Sidebar from './Sidebar.vue';
import LinkSummary from './LinkSummary.vue';
import UserFeed from './UserFeed.vue';
import AppNavbar from './AppNavbar.vue';
import { UserInfo } from "@/types/User";

import { ContactInfo } from "@/types/portal/Person";
import personService from "@/services/portal/personService";

export default Vue.extend({
  name: 'Home',
  data () {
    return {
      drawer: false,
      contactInfo: <ContactInfo>{
        firstName: "",
        lastName: "",
        address: {
          street: "",
        }
      },
    }
  },
  props: {
    searchText: String,
  },
  components: {
    Sidebar,
    LinkSummary,
    UserFeed,
    AppNavbar,
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    }
  },
  created: function () {
    this.loadUserContactInfo();
  },
  watch: {
    userInfo: function (newVal, oldVal) {
      if (newVal === oldVal) return;

      this.loadUserContactInfo();
    },
  },
  methods: {
    toggleDrawer: function () {
      this.drawer = !this.drawer
    },
    loadUserContactInfo: function () {
      personService
        .getContactInformation(this.userInfo.personId)
        .then((res) => {
          console.log(res)
          if (res.found) {
            this.contactInfo = Object.assign({}, res.data);
        }}).catch((e) => console.log(e));
    },
  }
});

