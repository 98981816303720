
import Vue from "vue";
export default Vue.extend({
  name: "PuppyTable",
  data: function () {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Sex", value: "sex" },
        { text: "Main Color", value: "mainColor" },
        { text: "Dead/Culled?", value: "isDead" },
        { text: "Breeder Notes", value: "notes" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      sexChoices: [
        { description: "Male", abbreviation: "M" },
        { description: "Female", abbreviation: "F" },
      ],
      editedIndex: -1,
      editedItem: {
        sex: "M",
        mainColor: "BM",
        isDead: false,
        notes: "",
      },
      defaultItem: {
        sex: "M",
        mainColor: "BM",
        isDead: false,
        notes: "",
      },
    };
  },
  methods: {
    editItem(item: any) {
      this.$data.editedIndex = (this as any).puppyInfo.indexOf(item);
      this.$data.editedItem = Object.assign({}, item);
      this.$data.dialog = true;
    },

    deleteItem(item: any) {
      this.$data.editedIndex = (this as any).puppyInfo.indexOf(item);
      this.$data.editedItem = Object.assign({}, item);
      this.$data.dialogDelete = true;
    },

    deleteItemConfirm() {
      (this as any).puppyInfo.splice(this.$data.editedIndex, 1);
      this.$nextTick(() => {
        this.changePuppyInfo((this as any).puppyInfo, this.sire.dogId);
        (this as any).closeDelete();
      });
    },

    close() {
      this.$data.dialog = false;
      this.$nextTick(() => {
        this.$data.editedItem = Object.assign({}, this.$data.defaultItem);
        this.$data.editedIndex = -1;
      });
    },

    closeDelete() {
      this.$data.dialogDelete = false;
      this.$nextTick(() => {
        this.$data.editedItem = Object.assign({}, this.$data.defaultItem);
        this.$data.editedIndex = -1;
      });
    },

    save() {
      if (this.$data.editedIndex > -1) {
        Object.assign(
          (this as any).puppyInfo[this.$data.editedIndex],
          this.$data.editedItem
        );
        this.$nextTick(() => {
          this.changePuppyInfo((this as any).puppyInfo, this.sire.dogId);
        });
      } else {
        this.changePuppyInfo(
          [...(this as any).puppyInfo, this.$data.editedItem],
          this.sire.dogId
        );
      }
      (this as any).close();
    },
  },
  computed: {
    allowedColorChoices(): any[] {
      if (this.dam.mainColor?.toUpperCase().includes("RED")
        && this.sire.mainColor?.toUpperCase().includes("RED"))
        return this.mainColorChoices.filter(c => (c as { abbreviation: string, description: string }).description.toUpperCase().includes("RED"));
      else return this.mainColorChoices;
    },
    formTitle() {
      return this.$data.editedIndex === -1 ? "New Puppy" : "Edit Puppy";
    },
  },
  props: {
    dam: Object,
    sire: Object,
    puppyInfo: [] as any[],
    changePuppyInfo: Function,
    mainColorChoices: Array,
  },
});
