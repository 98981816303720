
import Vue from "vue";
import { debounce } from "throttle-debounce";

import { UserInfo } from "@/types/User";
import ASCAArtIncLink from "@/components/common/ASCAArtIncLink.vue";
import BylawsLink from "@/components/common/BylawsLink.vue";
import FooterText from "@/components/common/FooterText.vue";
import CountryDropdown from "@/components/common/inputs/CountryDropdown.vue";
import StateDropdown from "@/components/common/inputs/StateDropdown.vue";
import registerAffiliateService from "@/services/portal/registerAffiliateService";
import { Countries } from "@/constants";

export default Vue.extend({
  name: "AffiliateAddress",
  components: {
    FooterText,
    CountryDropdown,
    StateDropdown,
    ASCAArtIncLink,
    BylawsLink,
  },
  props: {
    changePage: Function,
    changeFirstPageInfo: Function,
    clubName: String,
    address: Object,
    termOfOffice: null || Number,
    affiliateWebsite: String,
    price: Number,
  },
  data: function () {
    return {
      isValid: true,
      nameMatch: null,
      country_USA: Countries.USA,    
    };
  },
  watch: {
    clubName: {
      handler: debounce(
        500,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal) return;
          if (newVal) {
            registerAffiliateService
              .getMatchingAffiliateNames(newVal)
              .then((response: String[]) => {
                console.log(response);
                if (response.length > 0) {
                  this.nameMatch = response.join(", ");
                } else {
                  this.nameMatch = null;
                }
              });
          }
        }
      ),
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    nameMatchErrorMessage(): string {
      return this.nameMatch
        ? `Name is the same as existing affiliate: ${this.nameMatch}. Please choose another name.`
        : "";
    },
  },
  methods: {
    validateWebsite(input: string) {
      return /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(input) || input === '' || "Website is invalid"
    },
    validateCity(v: string) {
      return this.address.country === Countries.USA
        ? !!v || 'City is required'
        : true
    },
    validateZip(v: string) {
      return this.address.country === Countries.USA
        ? !!v || 'Zip/Postal is required'
        : true
    },
    updateCountry: async function (v: string) {
      this.address.country = v;

      await Vue.nextTick();

      (this.$refs.addressForm as any).validate();
    },
  },
});
