
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import ReportViewer from "@/components/Admin/Reports/ReportViewer.vue";
import dogService from "@/services/dogService";
import { UserInfo } from "@/types/User";

const defaultImage = require('@/assets/photouploadblue-small.png');

export default Vue.extend({
  name: "DogInfo",
  components: {
    CoownersList,
    ReportViewer
  },
  data: function () {
    return {
      defaultImage,
      headers: [
        { text: "Show Date", align: "left", value: "showDate" },
        { text: "Descr.", align: "left", value: "description" },
        { text: "City", align: "left", value: "city" },
        { text: "State", align: "left", value: "state" },
        { text: "Country", align: "left", value: "country" },
        { text: "Time", align: "left", value: "time" },
        { text: "Category", align: "left", value: "category" },
        { text: "Division", align: "left", value: "division" },
        { text: "Score", align: "left", value: "score" },
        { text: "Place", align: "left", value: "place" },
        { text: "Hit", align: "left", value: "hit" },
        { text: "PT Sched", align: "left", value: "ptSched" },
        { text: "# Competing", align: "left", value: "nCompeting" },
        { text: "Handler", align: "left", value: "handler" },
        { text: "Judge", align: "left", value: "judge" },
      ],
      dogInfo: {},
      showResults: [] as any[],
      leases: [] as any[],
      showLeasesDialog: false,
      selectedTitle: "",
      Report_Name: 'Asca.Api.Reports.rpt8110_Title_Certificate.rdlx',
      Report_URL: '`/Admin/reports/certificate/${selectedTitle}`',
      showReportDialog: false,
      showReportDialogFull: false,
      showPedigreeDialog: false,
      showPedigreeSmallDialog: false,
      showRegCardDialog: false,
    };
  },
  
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    dogOwnershipType: function () {
      return OwnershipType.Dog;
    },
    leaseOwnershipType: function () {
      return OwnershipType.Lease;
    },
  },
  methods: {
    showPedigreeReport: function (){
      this.Report_Name = "Asca.Api.Reports.rpt3240_Dog_Pedigree_Report_New2.rdlx";
      this.Report_URL = `/reports/pedigree/${encodeURIComponent(this.dogId.trim())}`;
      this.showReportDialog = true;
    },
    showRegCard: function (){
      this.Report_Name = "Asca.Api.Reports.rpt3210_Dog_Reg_Certificate.rdlx";
      this.Report_URL = `/reports/registration/${encodeURIComponent(this.dogId.trim())}`;
      this.showReportDialog = true;
    },
    showTitle: function (title: any){
      (title.isSuffix && !(title.title.includes('DNA'))) ? this.Report_Name= 'Asca.Api.Reports.rpt8110_Title_Certificate.rdlx' : this.Report_Name= 'Asca.Api.Reports.rpt8111_Title_Certificate_Full.rdlx';
      this.selectedTitle = title.certificateId;
      this.Report_URL = '/Admin/reports/certificate/'+this.selectedTitle
      this.showReportDialog = true;
    }
  },
  created: function () {
    if (this.dogId && this.userInfo) {
      dogService
        .getDogById(this.dogId, this.userInfo.personId)
        .then((res) => {
          this.dogInfo = res;
        })
        .catch((e) => console.log(e));
      dogService
        .getDogShowResultsById(this.dogId, this.userInfo.personId)
        .then((res) => {
          this.showResults = res;
        })
        .catch((e) => console.log(e));
      dogService
        .getDogLeases(this.dogId)
        .then((res) => {
          this.leases = res.data;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    dogId: function (newVal: String) {
      dogService
        .getDogById(this.dogId, this.userInfo.personId)
        .then((res) => {
          this.dogInfo = res;
        })
        .catch((e) => console.log(e));
      dogService
        .getDogShowResultsById(this.dogId, this.userInfo.personId)
        .then((res) => {
          this.showResults = res;
        })
        .catch((e) => console.log(e));
      dogService
        .getDogLeases(this.dogId)
        .then((res) => {
          this.leases = res.data;
        })
        .catch((e) => console.log(e));
    },
    userInfo: function (newVal: object) {
      dogService
        .getDogById(this.dogId, this.userInfo.personId)
        .then((res) => {
          this.dogInfo = res;
        })
        .catch((e) => console.log(e));
      dogService
        .getDogShowResultsById(this.dogId, this.userInfo.personId)
        .then((res) => {
          this.showResults = res;
        })
        .catch((e) => console.log(e));
      dogService
        .getDogLeases(this.dogId)
        .then((res) => {
          this.leases = res.data;
        })
        .catch((e) => console.log(e));
    },
    
  },
  props: {
    dogId: { type: String },
  },
});
