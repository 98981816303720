export class Messages {
    public static SaveSuccessful = "Changes successfully saved.";
    public static SaveError = "Changes couldn't be saved.";
}

export class MetaNames {
    public static DefaultTitle = "ASCA";
    public static DefaultLanding = "ASCA Home";

    public static AdminLitters = "Litters";
    public static AdminLitterRegistrations = "Litter Registrations";
    public static AdminLitterDetails = "Litter Details";

    public static AdminLeases = "Leases";
    public static AdminLeaseRegistrations = "Lease Registrations";
    public static AdminLeaseDetails = "Lease Details";

    public static AdminKennels = "Kennels";
    public static AdminPedigreePrintOrders = "Pedigree Print Orders";
    public static AdminRegisterPedigreeOrder = "Register Pedigree Order"
    public static AdminQTDOGRegistrations = "QT Dog Registrations";
    public static AdminKennelRegistrations = "Kennel Registrations";
    public static AdminKennelDetails = "Kennel Details";
    public static AdminKennelChangeOwners = "Kennel Change Owners";

    public static AdminAffiliates = "Affiliates";
    public static AdminAffiliateRegistrations = "Affiliate Registrations";
    public static AdminAffiliateRenewals = "Affiliate Renewals";
    public static AdminAffiliateDetails = "Affiliate Details";

    public static AdminPuppyReleases = "Puppy Releases";

    public static AdminShows = "Shows";
    public static AdminShowDetails = "Show Details";

    public static AdminPeople = "People";
    public static AdminPersonDetails = "Person Details";

    public static AdminDogs = "Dogs";
    public static AdminDogDetails = "Dog Details";

    public static AdminPayments = "Payments";

    public static MembershipReport = "Membership Report";

    public static AdminLookupMaintenance = "Lookup Maintenance";
    public static AdminIdConfig = "ID Config";

    public static AdminTitlingReview = "Titling Review";
    public static AdminCertificateGeneration = "Certificate Generation";
    public static AdminCertificatePrinting = "Certificate Printing";
    public static AdminCategoryManagement = "Category Management";
    public static AdminShowResults = "Show Results";
    public static AdminEvents = "Event Sanctions";
    public static AdminHOF = "HOF Requests"

    public static AdminReports = "Reports";

    //Side Panel Headings
    public static AdminTitlingHeading = "Events & Titling";
    public static AdminRegistrationsHeading = "Registrations";
    public static AdminSearchesHeading = "Data & Searches";
    public static AdminReportsHeading = "Reports & Printing";
    public static AdminUtilitiesHeading = "Utilities";
    public static AdminMembersOnlyEdit = "Members Only Edit";

}

export class LitterRegistration {    
    public static MissingDNA_Dam = "This dam does not have DNA on file with ASCA.";
    public static MissingDNA_Sire = "This sire does not have DNA on file with ASCA.";
    public static MissingDNA_Parents = "At least one of the parents for this litter does not have DNA on file with ASCA.";
    public static GeneticTesting_Link = " Please <a href='https://asca.org/wp-content/uploads/2022/09/dnaauthform.pdf' target='_blank'>submit DNA samples to PawPrint Genetics</a> to order a DNA Profiling Kit and submit the ASCA DNA Authorization Form with your sample to the lab to have the DNA recorded in the ASCA database. Learn more by reading <a href='https://asca.org/aussies/about-aussies/dna/the-how-what-and-why-of-dna-testing/' target='_blank'>The How, What, and Why of DNA Testing</a>.";

}

export class Countries {
    public static Canada = "CANADA";
    public static USA = "UNITED STATES";
}


export class PedigreeTypes {
    public static Digital = 0;
    public static Small = 1;
    public static Large = 2;
}