
import Vue from "vue";

import passwordValidators from '@/helpers/validate-password';

import personService from "@/services/portal/personService";
import { VerificationType } from "@/services/codeVerificationService";

import VerifyCodeDialog from "@/components/common/VerifyCodeDialog.vue";
import FooterText from '@/components/common/FooterText.vue';
import PasswordRules from '@/components/common/PasswordRules.vue';

export default Vue.extend({
  name: "ForgotPassword",
  components: {
    FooterText,
    VerifyCodeDialog,
    PasswordRules,
  },
  data: function () {
    return {
      isValid: false,
      isError: false,
      isProcessing: false,
      errorMessage: '',
      inputSearchText: false,
      verifiedEmail: false,
      showEmailCodeDialog: false,
      searchValue: "",
      confirmedUser: {} as any,
      newPassword: "",
      newPasswordConfirm: "",
      alertType: "",
      alertMessage: "",
      passwordRules: passwordValidators,
    };
  },
  watch: {
    confirmedUser: function (newValue) {
      if (!!newValue.wholeEmail) this.showEmailCodeDialog = true;
    },
  },
  computed: {
    emailVerificationType: function () {
      return VerificationType.Email;
    },
  },
  methods: {
    goBack() { this.$router.back(); },
    closeEmailCodeDialog: function () {
      this.showEmailCodeDialog = false;
    },
    emailVerifiedSuccessfully: function () {
      this.verifiedEmail = true;
      this.showEmailCodeDialog = false;
    },
    onSearch: async function () {
      this.inputSearchText = false;
      this.isProcessing = true;
      this.isError = false;
      this.confirmedUser = {};
      this.errorMessage = "";

      try {
        const response = await personService.getUserConfirmation(this.searchValue, false);

        if (response.success) {
          this.isError = false;
          this.confirmedUser = response;
        } else {
          this.isError = true;
          this.errorMessage = response.error;
        }
      } finally {
        this.isProcessing = false;
      }
    },
    onChangePassword: async function () {
      this.isProcessing = true;
      
      const response = await personService.forgotPassword(this.confirmedUser.personId, this.newPassword);

      if (response.success) {
        this.alertType = "success";
        this.alertMessage = "Password updated successfully!";
      } else if (response.errorMessage) {
        this.alertType = "error";
        this.alertMessage = response.errorMessage;
      } else {
        this.alertType = "error";
        this.alertMessage = "Something unexpectedly went wrong.";
      }

      this.isProcessing = false;
    },
  },
});
