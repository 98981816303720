
import Vue, { PropType } from "vue";
import { debounce } from "throttle-debounce";
import { UserInfo } from "@/types/User";
import LitterFeeTable from "@/components/Portal/LitterFeeTable.vue";
import FooterText from "@/components/common/FooterText.vue";

import { LitterRegistration } from "@/constants";

export default Vue.extend({
  name: "RegisterLitterForm1",
  data: function () {
    return {
      rulesDialog: false,
      damDnaVerifiedWarning: LitterRegistration.MissingDNA_Dam,    
      geneticTestingLink: LitterRegistration.GeneticTesting_Link,
    };
  },
  methods: {
    toggleRules: function () {
      this.$data.rulesDialog = !this.$data.rulesDialog;
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    isValid: function () {
      //request needs to be validated
      //if whelping date is invalid, then explanation has to be provided
      return (
        (this as any).validationInfo.isRequestValid &&
        ((this as any).validationInfo.isWhelpingDateValid ||
          (this as any).whelpingDateExplanation)
      );
    },
  },
  watch: {
    picker: {
      handler: debounce(
        500,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal) return;
          if (newVal) {
            this.changeDate(newVal);
            this.validateWhelpingDate(
              this.userInfo.personId,
              this.dogId,
              newVal
            );
            this.searchDam(this.dogId, new Date(newVal));
          }
        }
      ),
    },
  },
  props: {
    changePage: Function,
    damInfo: Object,
    searchDam: Function,
    dogId: String,
    picker: String,
    changeDate: Function,
    whelpingDateExplanation: String,
    isArtificialInsemination: Boolean,
    changeWhelpingDateExplanation: Function,
    changeArtificialInsemination: Function,
    validateWhelpingDate: Function,
    validationInfo: Object,
    minDate: { type: [null, String] as PropType<null | string> },
    maxDate: { type: [null, String] as PropType<null | string> },
    priceInfo: Array,
  },
  components: {
    LitterFeeTable,
    FooterText,
  },
});
