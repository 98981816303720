
import Vue from "vue";
import router from "@/router";

import FooterText from "@/components/common/FooterText.vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";

import adminService, {AdminSearchCoownersResult} from '@/services/adminService';
import registerLeaseService from "@/services/portal/registerLeaseService";
import { getPedigreePrintTypesList } from "@/services/lookupsService";
import pedigreeService from '@/services/portal/pedigreeService';
import adminPedigreeService from "@/services/admin/pedigreeService";
import { PedigreeTypes } from "@/constants";

export default Vue.extend({
  components: { FooterText, CoownersList, Datepicker, },
  name: "AdminRegisterLease",
  data: function () {
    return {
        isLoading: false,
        hasSearched: false,
        dogId: '',
        dogInfo: {
          owners: [] as any[]
        } as any,
        memberId: null,
        memberName: '',
        feesTable: {} as any,
        errorMessage: '',
        submittedAt: '',
        paidAt: '',
        adminNotes: '',
        fee: null,
        printTypes: [] as any[],
        selectedPedigreePrintType: null,
        pedigreeFees: {
          digital: {description: '', price: null},
          small: {description: '', price: null},
          large: {description: '', price: null},
        },        
        pedigreeTypes_digital: PedigreeTypes.Digital,    
        pedigreeTypes_small: PedigreeTypes.Small,
        pedigreeTypes_large: PedigreeTypes.Large,
        paymentNotes: '',
        paymentTypes: [] as any[],
        selectedPaymentType: null,
        peopleSearchDialog: false,
        selectedRequester: null as any,
        searchPeopleHeaders: [
            { text: "Member ID", align: "center", value: "memberId" },
            { text: "Name", align: "center", value: "name" },
            { text: "Active member?", align: "center", value: "isActiveMember" },
            { text: "", align: "center", value: "" },

        ],
        searchPeopleResults: [] as AdminSearchCoownersResult[],
        pedigreeOrdersPath: '/admin/registration/pedigrees',   
        skipPayment: false,
    };
  },
  computed: {
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
    leaseOwnershipType: function() {
      return OwnershipType.Lease;
    },    
    userInfo: function() {
      return this.$store.state.userInfo;
    },
    isValid: function() {
      return !!this.$data.dogId && !!this.$data.selectedRequester && !!this.$data.selectedPedigreePrintType;
    },
  },
  created() {
    this.printTypes = getPedigreePrintTypesList();
    adminService.getPaymentTypes().then((res) => {
      this.paymentTypes = res;
    });
    this.getFees();
  },
  watch: {
    selectedPedigreePrintType: function(newValue) { 
      const fee = newValue === PedigreeTypes.Digital ? this.pedigreeFees.digital.price
                      : newValue === PedigreeTypes.Small ? this.pedigreeFees.small.price
                      : newValue === PedigreeTypes.Large ? this.pedigreeFees.large.price
                      : 0;
      this.$data.fee = fee;
    },
    selectedRequester: function(newValue){
      if (!!newValue){
        this.$data.memberId = newValue.memberId;
      } else {
        this.$data.memberId = null;
      }
    }
  },
  methods: {
    calculateFee: function(pedigreeType: number){
      const fee = pedigreeType === PedigreeTypes.Digital ? this.pedigreeFees.digital.price
                      : pedigreeType === PedigreeTypes.Small ? this.pedigreeFees.small.price
                      : pedigreeType === PedigreeTypes.Large ? this.pedigreeFees.large.price
                      : 0;
      this.$data.fee = fee;
    },
    updateDates: function (val: any, key: string) {
      (this as any)[key] = val.date;
    },       
    getFees: async function() {

      this.pedigreeFees = await pedigreeService.getFees();
    },
    submit() {
        this.$store.dispatch("changeLoaderStatus",true);
        this.errorMessage = '';
        const request = {
            adminPersonId: this.userInfo.personId,
            requesterPersonId: this.selectedRequester.personId,
            dogId: this.dogId,
            submissionDate: this.submittedAt,
            adminNotes: this.adminNotes,
            fee: this.fee,
            paymentInfo: this.skipPayment ? null : {
                paymentType: this.selectedPaymentType,
                adminNotes: this.paymentNotes,
                amount: this.fee,
                isCaptured: true,
                paidAt: this.paidAt

            } ,
            pedigreePrintType: this.selectedPedigreePrintType,
        };
        adminPedigreeService.registerPedigreeOrder(request).then((res)=>{
            if(!!res.messages){
              this.errorMessage = res.messages.join(', ');
            }

            if(res.success){
              router.push({ path: this.pedigreeOrdersPath});
            }
          })
          .finally(() => {
            this.$store.dispatch("changeLoaderStatus",false);
          })
        ;
    },
    loadDogInfo(){
        registerLeaseService.getLeasedDog(this.dogId).then((res) => {
            if(!res){
              this.errorMessage = "Dog information reuquired for lease not found.";  
              this.dogInfo = {} as any;        
            } else {
              this.dogInfo = res;
              this.errorMessage ='';
            }
        }).catch((e:any) => {
          this.errorMessage = "Dog information not found.";  
          this.dogInfo = {} as any;   
        });
    },
    findPerson() {
      this.isLoading = true;
      this.hasSearched = false;
      adminService
        .adminSearchCoowners({
          memberId: this.$data.memberId,
          nameSearchText: this.$data.memberName,
          judgeId: ''
      })
        .then((response: any) => {
          this.isLoading = false;
          this.hasSearched = true;
          this.searchPeopleResults = response;
        });
    },
  },
});
