
  import Vue from 'vue';

import CountryDropdown from '@/components/common/inputs/CountryDropdown.vue';
import StateDropdown from '@/components/common/inputs/StateDropdown.vue';
import FooterText from '@/components/common/FooterText.vue';
import HostedRegistrationPayment from '@/components/common/HostedRegistrationPayment.vue';

import userService from '@/services/portal/userService';
import pedigreeService from '@/services/portal/pedigreeService';
import AuthorizeNetResponseData from '@/types/AuthorizeNetResponseData';
import { Countries, PedigreeTypes } from "@/constants";
  
  import { UserInfo } from "@/types/User";
  
  const createAccountImage = require('@/assets/create-account.png');
  const logoImage = require('@/assets/logo.svg');
  
  export default Vue.extend({
    components: { FooterText, CountryDropdown, StateDropdown, HostedRegistrationPayment },
    name: 'PedigreeOrder',
    mounted: function() {
      this.initialize();
    },
    props: {
      dogId: { type: String },
      dogName: { type: String },
    },
    data: function () {
      return {
        createAccountImage,
        logoImage,
        isProcessing: false,
        errorMessage: '',
        showSuccess: false,

        country_USA: Countries.USA,    
        country_Canada: Countries.Canada,
        
        pedigreeTypes_digital: PedigreeTypes.Digital,    
        pedigreeTypes_small: PedigreeTypes.Small,
        pedigreeTypes_large: PedigreeTypes.Large,

        country: Countries.USA,
        address: "",
        international: "",
        city: "",
        stateProvince: "",
        zipPostal: "",
        aussieTimesDisplayPrice: "",
        isValidAddress: false,
        isValidCard: false,

        pedigreeSelected: PedigreeTypes.Large,

        pedigreeFees: {
          digital: {description: '', price: null},
          small: {description: '', price: null},
          large: {description: '', price: null},
        },
      }
    },
    watch: {
      userInfo: function(newValue) {
        if (!!newValue) {
          this.initialize();
        }
      }
    },
    computed: {
      userInfo(): UserInfo {
        return this.$store.state.userInfo;
      },
      isPrintedSelection() {
        return !!this.$data.pedigreeSelected && (this.$data.pedigreeSelected == PedigreeTypes.Small || this.$data.pedigreeSelected == PedigreeTypes.Large);
      }
    },
    methods: {
      initialize: async function () {
        const userInfo = this.userInfo;
    
        if (!!userInfo) {
          this.country = userInfo.country;
          this.address = userInfo.address;
          this.international = userInfo.international;
          this.city = userInfo.city;
          this.stateProvince = userInfo.stateProvince;
          this.zipPostal = userInfo.zipPostal;
        }

        this.getFees();
    },            
    getFees: async function() {
      this.isProcessing = true;

      this.pedigreeFees = await pedigreeService.getFees();

      this.isProcessing = false;
    },
    validateCity(v: string) {
      return this.country === Countries.USA
        ? !!v || 'City is required'
        : true
    },
    validateZip(v: string) {
      return this.country === Countries.USA
        ? !!v || 'Zip/Postal is required'
        : true
    },
    updateCountry: async function (v: string) {
      this.country = v;

      await Vue.nextTick();

      (this.$refs.addressForm as any).validate();
    },
    returnHome() {
      this.$router.push('/');
    },
    pay: async function (data: AuthorizeNetResponseData) {
      this.isProcessing = true;

      try {
        const fee = this.pedigreeSelected === PedigreeTypes.Digital ? this.pedigreeFees.digital.price
                    : this.pedigreeSelected === PedigreeTypes.Small ? this.pedigreeFees.small.price
                    : this.pedigreeSelected === PedigreeTypes.Large ? this.pedigreeFees.large.price
                    : 0;
                    
        const response = await pedigreeService.orderPedigree({
          country: this.country,
          address: this.address,
          international: this.international,
          city: this.city,
          stateProvince: this.stateProvince,
          zipPostal: this.zipPostal,
          fee: fee,
          paymentInfo: data, 
          requesterPersonId: this.userInfo.personId,
          dogId: this.$props.dogId,
          pedigreePrintType: this.pedigreeSelected
        });

        if (response.success == true) {
          const newUserInfo = await userService.getProfile();

          this.$store.commit('loadUserInfo', newUserInfo);

          this.isProcessing = false;
          this.showSuccess = true;
        } else {
          this.isProcessing = false;
          this.errorMessage = response.errorMessage ? response.errorMessage : 'An Unknown Error Occured';
        }
      } catch (ex) {
        this.isProcessing = false;
        this.errorMessage = 'An Unknown Error Occured';
      }

      this.isProcessing = false;
    },
  },
  });
  