import { AdminTasksSummary } from "./adminService";
import { MetaNames } from "@/constants";

import { MemberType } from '@/types/Member';
import { PersonTasksSummary } from "@/types/portal/Person";

const ALL_BUT_JUNIOR = [
  MemberType.Affiliate,
  MemberType.Dual,
  MemberType.Single,
  MemberType.Lifetime,
  MemberType.LifetimeDual,
  MemberType.Service,
];

const FULL_MEMBERS = [
  MemberType.Dual,
  MemberType.Single,
  MemberType.Lifetime,
  MemberType.LifetimeDual,
];

export type MenuLinkVM = {
  text: String;
  icon?: String;
  link?: String;
  to?: String;
  image?: String;
  count?: number;
  forMembers?: boolean | MemberType[];
};

export type MenuLinkSummaryVM = {
  image?: String;
  title: String;
  count?: number;
  expandable?: boolean;
  items: MenuLinkVM[];
  forMembers?: boolean | MemberType[];
};

export const getMenuLinkSummariesForAdmin = (
  adminTasksSummary: AdminTasksSummary
): MenuLinkSummaryVM[] => {
  return [
      {
          title: MetaNames.AdminTitlingHeading,
          items: [
              {
                  text: MetaNames.AdminTitlingReview,
                  link: "/admin/titling/review",
              },
              {
                  text: MetaNames.AdminCertificateGeneration,
                  link: "/admin/titling/process",
              },
              {
                  text: MetaNames.AdminCertificatePrinting,
                  link: "/admin/certificates",
              },
              {
                  text: MetaNames.AdminEvents,
                  count: adminTasksSummary.eventsSanctions,
              },
              {
                  text: MetaNames.AdminShowResults,
                  count: adminTasksSummary.showResults,
              },
              {
                  text: MetaNames.AdminHOF,
                  count: adminTasksSummary.hofRequests,
              },
          ],
      },
      {
          title: MetaNames.AdminRegistrationsHeading,
          items: [
              {
                text: "Pedigree Prints Orders",
                link: "/admin/registration/pedigrees",
                count: adminTasksSummary.pedigreePrintOrders,
              },
              {
                  text: "Individual Dog Registrations",
                  count: adminTasksSummary.individualDogRegistrations,
              },
              {
                  text: "LEP/Other Registrations",
                  count: adminTasksSummary.lepOtherRegistrations,
              },
              {
                  text: "QT Dog Registrations",
                  link: "/admin/registration/qtdogs",
                  count: adminTasksSummary.qtDogRegistrations,
              },
              {
                  text: MetaNames.AdminKennelRegistrations,
                  link: "/admin/registration/kennels",
                  count: adminTasksSummary.kennelRegistrations,
              },
              {
                  text: MetaNames.AdminKennelChangeOwners,
                  link: "/admin/changekennelowners",
                  count: adminTasksSummary.kennelOwnersChangeRegistrations,
              },
              {
                  text: MetaNames.AdminLeaseRegistrations,
                  link: "/admin/registration/leases",
                  count: adminTasksSummary.leaseRegistrations,
              },
              {
                  text: MetaNames.AdminLitterRegistrations,
                  link: "/admin/registration/litters",
                  count: adminTasksSummary.litterRegistrations,
              },
              // {
              //     text: MetaNames.AdminPuppyReleases,
              //     link: "/admin/registration/puppyrelease",
              //     count: adminTasksSummary.puppyReleases,
              // },
              {
                  text: MetaNames.AdminAffiliateRegistrations,
                  link: "/admin/registration/affiliate",
                  count: adminTasksSummary.affiliateRegistrations,
              },
              {
                  text: MetaNames.AdminAffiliateRenewals,
                  link: "/admin/renew/affiliate",
                  count: adminTasksSummary.affiliateRegistrations,
              },
          ],
      },
      {
          title: MetaNames.AdminSearchesHeading,
          items: [
              {
                  text: MetaNames.AdminPayments,
                  link: "/admin/payments",
              },
              {
                  text: MetaNames.AdminLeases,
                  link: "/admin/search/leases",
              },
              {
                  text: MetaNames.AdminLitters,
                  link: "/admin/search/litters",
              },
              {
                  text: MetaNames.AdminKennels,
                  link: "/admin/search/kennels",
              },
              {
                  text: MetaNames.AdminShows,
                  link: "/admin/search/shows",
              },
              {
                  text: MetaNames.AdminDogs,
                  link: "/admin/search/dogs",
              },
              {
                  text: MetaNames.AdminPeople,
                  link: "/admin/search/people",
              },
              {
                  text: MetaNames.AdminAffiliates,
                  link: "/admin/search/affiliates",
              },
              {
                  text: MetaNames.AdminPuppyReleases,
                  link: "/admin/search/puppyrelease",
              },
          ],
      },
      {
        title: MetaNames.AdminReportsHeading,
        items: [
          {
            text: MetaNames.MembershipReport,
            link: "/admin/reports/membership"
          },
        ]
      },
      {
          title: MetaNames.AdminUtilitiesHeading,
          items: [
              {
                  text: MetaNames.AdminLookupMaintenance,
                  link: "/admin/lookups",
              },
              {
                  text: MetaNames.AdminIdConfig,
                  link: "/admin/idconfig",
              },
              {
                  text: MetaNames.AdminMembersOnlyEdit,
                  link: "/admin/members-only",
              },

          ],
      },
  ];
};

export const getMenuLinkSummariesForPerson = (
  personTasksSummary: PersonTasksSummary
): MenuLinkSummaryVM[] => {
  return [
    {
      title: "Pending Registrations",
      items:
        personTasksSummary.myRequests?.map(
          (t) => <MenuLinkVM>{ text: t.title, image: t.imageUrl, link: t.link, count: t.count }
        ) || Array<MenuLinkVM>(),
      expandable: true,
      forMembers: true,
    },
    {
      title: "Pending Signatures",
      items:
        personTasksSummary.signatures?.map(
          (t) => <MenuLinkVM>{ text: t.title, image: t.imageUrl, link: t.link, count: t.count }
        ) || Array<MenuLinkVM>(),
      expandable: true,
    },
    {
      title: "My Information",
      items: [
        { text: "Dogs", link: "/", forMembers: true },
        { text: "Profile", link: "/profile" },
        { text: "Membership", link: "/profile/membership" },
        { text: "Payments", link: "/profile/payments" },
        { text: "Kennels", link: "/profile/kennels", forMembers: ALL_BUT_JUNIOR },
        { text: "Litters", link: "/profile/litters", forMembers: true },
        { text: "Pedigrees", link: "/profile/pedigrees" },
      ],
    },
    {
      title: "Services",
      items: [
        { text: "Search dogs", link: "/?tab=search"  },
        { text: "Other Registry Registration", forMembers: [] },
        { text: "LEP Registration", forMembers: [] },
        { text: "QT Registration", link: "/registration/qtdog" },
        { text: "Pedigree Request", to: "/profile/pedigrees", forMembers: true },
        { text: "Kennel Registration", link: "/registration/kennel", forMembers: FULL_MEMBERS },
        { text: "Affiliate Registration", link: "/registration/affiliate", forMembers: FULL_MEMBERS },
        { text: "Hall of Fame Registration", forMembers: [] },
      ],
      expandable: true,
      forMembers: true,
    },
    {
      title: "My Affiliates",
      items: personTasksSummary.myAffiliates?.map(
        (t) => <MenuLinkVM>{ text: t.title, image: t.imageUrl, link: t.link }
      ) || Array<MenuLinkVM>(),
      expandable: true,
      forMembers: [], // TODO: After initial push we can see this again ALL_BUT_JUNIOR,
    },
    {
      title: "Resources",
      items: [
        { text: 'Aussie Times', link: '/aussietimes' },
        { text: 'Kennel Listings', to: ' https://docs.google.com/document/d/1yp9zVV7dA_IvfmXEmW85QV39XVZbX8OZ0ncsK0Vg-0w/edit?tab=t.0' },
        { text: 'Affiliate Listings', to: 'https://asca.org/affiliateclubs/' },
        { text: "Show Results", link: "/showresults" },
        { text: "Members Only", link: "/members-only" },
      ],
      expandable: true,
      forMembers: true,
    },
  ];
};

export default {
  getMenuLinkSummariesForAdmin,
  getMenuLinkSummariesForPerson,
};
