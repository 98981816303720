
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';
import ASCAArtIncLink from "@/components/common/ASCAArtIncLink.vue";
import BylawsLink from "@/components/common/BylawsLink.vue";
import HostedRegistrationPayment from '@/components/common/HostedRegistrationPayment.vue';

import userService from '@/services/portal/userService';
import memberService from '@/services/portal/memberService';

import AuthorizeNetResponseData from '@/types/AuthorizeNetResponseData';
import { UserInfo } from '@/types/User';
import { MemberType } from '@/types/Member';
import { Countries } from "@/constants";

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
  name: 'SummaryPaymentInformation',
  components: { FooterText, ASCAArtIncLink, BylawsLink, HostedRegistrationPayment },
  data() {
    return {
      logoImage,
      isValidCard: false,
      isProcessing: false,
      errorMessage: '',

      plan: null,
      postagePrice: 0,
      isDual: false,
      isJunior: false,
      subscribe: false,
      firstClass: false,
      juniorCount: 0,

      total: 0,
      signature: '',
      agreementDate: new Date(),

      showSuccessRegister: false,
      showSuccessEmailRegister: false,
      sentEmails: [],
      failedEmails: [],
    }
  },
  watch: {
    membership: function(newValue) {
      if (newValue) {
        const plan = this.membership.plan;
        const subscribe = this.membership.subscribe;
        const firstClass = this.membership.firstClass;

        const isJunior = plan.memberType === MemberType.Junior;
        const isDual = plan.MemberType === MemberType.Lifetime || plan.memberType === MemberType.LifetimeDual;

        const postagePrice = subscribe
          ? plan.aussieTimesPrice + (firstClass ? this.firstClassPrice : this.standardPrice)
          : 0;

        const total = plan.price + postagePrice;

        this.plan = plan;
        this.isJunior = isJunior;
        this.isDual = isDual;
        this.total = total;
        this.postagePrice = postagePrice;
        this.subscribe = subscribe;
        this.firstClass = firstClass;
        this.juniorCount = this.membership.juniors.length;
      }
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    membership() {
      return this.$store.state.registerRenewMembershipInfo;
    },
    isRenew() {
      return this.$store.state.registerRenewMembershipInfo.isRenew;
    },
    plan() {
      return this.$store.state.registerRenewMembershipInfo.plan;
    },
    standardPrice() {
      const primary = this.$store.state.registerRenewMembershipInfo.primary;
      const aussieTimeFees = this.$store.state.registerRenewMembershipInfo.aussieTimeFees;

      if (!!aussieTimeFees && primary.country === Countries.Canada) {
        return aussieTimeFees.surfacePostageCanada;
      } else if (!!aussieTimeFees && primary.country !== Countries.USA) {
        return aussieTimeFees.surfacePostageOther;
      }

      return 0;
    },
    firstClassPrice() {
      const primary = this.$store.state.registerRenewMembershipInfo.primary;
      const aussieTimeFees = this.$store.state.registerRenewMembershipInfo.aussieTimeFees;

      let price = 25;

      if (!!aussieTimeFees && primary.country === Countries.USA){        
        return aussieTimeFees.firstClassUSA;
      } else if(!!aussieTimeFees && primary.country === Countries.Canada) {
        return aussieTimeFees.firstClassUSA;
      } else if (!!aussieTimeFees) {
        return aussieTimeFees.firstClassOther;
      }

      return price;
    }
  },
  methods: {
    standardText() {
      const aussiePrice = `1-Year Subscription to Aussie Times ${!!this.plan.aussieTimesPrice ? `($${this.plan.aussieTimesPrice}/yr)` : ''}`;
      const standardPostage = `Standard Postage ${!!this.standardPrice ? `($${this.standardPrice})` : ''}`;

      return `${aussiePrice} + ${standardPostage}`;
    },
    firstClassText() {
      const aussiePrice = `1-Year Subscription to Aussie Times ${!!this.plan.aussieTimesPrice ? `($${this.plan.aussieTimesPrice}/yr)` : ''}`;
      const firstClassPostage = `First Class Postage ($${this.firstClassPrice})`;

      return `${aussiePrice} + ${firstClassPostage}`;
    },
    goBack() {
      const plan = this.membership.plan;

      this.$store.commit('changeRegisterRenewMembershipInfo', {
        page: plan.memberType === MemberType.Service ? 1 : 2,
      });
    },
    returnHome() {
      this.$router.push('/');
      this.$store.commit('resetRegisterRenewMembershipInfo');
    },
    pay: async function (data: AuthorizeNetResponseData | null) {
      this.isProcessing = true;

      try {
        const response = this.membership.isRenew
          ? await memberService.renewMembership({
              signature: this.signature,
              primary: this.membership.primary,
              secondary: this.membership.secondary,
              juniors: this.membership.juniors,
              membershipType: this.membership.plan.memberType,
              subscribeToAussieTimes: this.membership.subscribe,
              firstClassSubscription: this.membership.firstClass,
              fee: this.total,
              paymentInfo: data
            })
          : await memberService.registerMembership({
              signature: this.signature,
              primary: this.membership.primary,
              secondary: this.membership.secondary,
              juniors: this.membership.juniors,
              membershipType: this.membership.plan.memberType,
              subscribeToAussieTimes: this.membership.subscribe,
              firstClassSubscription: this.membership.firstClass,
              fee: this.total,
              paymentInfo: data
            });
        
        if (response.success) {
          const newUserInfo = await userService.getProfile();

          this.$store.commit('loadUserInfo', newUserInfo);
          this.isProcessing = false;

          if (!response.emailsSent.length && !response.emailsFailed.length) {
            this.showSuccessRegister = true;
          } else {
            this.sentEmails = response.emailsSent;
            this.failedEmails = response.emailsFailed;
            this.showSuccessEmailRegister = true;
          }
        } else {
          this.isProcessing = false;
          this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
        }
      } catch (ex) {
        console.log(ex);

        this.isProcessing = false;
        this.errorMessage = 'An Unknown Error Occured';
      }

      this.isProcessing = false;
    },
  },
});
