
import Vue from "vue";
import DatepickerFormatted from "../../../common/inputs/DatepickerFormatted.vue";
import AdminEditableCoownersList, {
  OwnershipType,
} from "../../AdminEditableCoownersList.vue";
import AdminDogSearchDialog from "../../AdminDogSearchDialog.vue";
import AdminLitterPuppySearchDialog from "../../AdminLitterPuppySearchDialog.vue";

import dogService from "@/services/admin/dogService";
import lookupsService from "@/services/lookupsService";

import { Messages } from "@/constants";

export default Vue.extend({
  name: "DogRegistrationDetails",
  components: {
    AdminEditableCoownersList,
    DatepickerFormatted,
    AdminDogSearchDialog,
    AdminLitterPuppySearchDialog,
  },
  props: {
    dogId: String,
  },
  data: function () {
    return {
      newSireId: "",
      dogInfo: {
        dogId: "",
        owners: [] as any,
        puppyId: null,
        registrationDate: new Date().toISOString(),
        isRevoked: false,
        notForBreeding: false,
      } as any,
      persistedDogInfo: {
        damId: null,
        sireId: null,
        prevSireId: null,
      } as any,
      puppyInfo: {
        dogId: "",
      } as any,
      isEditMode: false,
      colors: {},
      selectedPuppyId: null,
      showValidationDialog: false,
      validationResult: { errors: [] as string[], warnings: [] as string[]},
      registrationsWithAutomaticIdGeneration: ["E", "F", "HIII", "LEP", "T"],
      ascaDogsRegistrations: ["E", "F", "HIII", "LEP"],
      revokableRegistrationTypes: ["E", "F"],
      showPuppiesSearchDialog: false,
      errorMessage: '',
      infoMessage: '',
      showErrorMessage: false,
      showInfoMessage: false,
      isChangingSire: false,
      puppyRefreshChanges: [] as string [],
      showPuppyRefreshChanges: false,
    };
  },
  computed: {
    dogOwnershipType: function () {
      return OwnershipType.Dog;
    },
    isAscaPuppyRegistration: function () {
      return !(
        this.$data.dogInfo.puppyId == null || !this.$data.dogInfo.puppyId
      );
    },
    canGenerateNewDogId: function () {
      return (
        this.$data.dogInfo &&
        this.$data.dogInfo.registrationType &&
        this.$data.registrationsWithAutomaticIdGeneration.includes(
          this.$data.dogInfo.registrationType.trim()
        )
      );
    },
  },
  created: function () {
    this.reloadData();
  },
  methods: {
    reloadData: function () {
      if (this.dogId) {
        dogService
          .getDogRegistrationInfoById(this.dogId)
          .then((res: any) => {
            this.dogInfo = Object.assign({}, res);
            this.persistedDogInfo = {
              damId: res.damId,
              sireId: res.sireId,
              prevSireId: res.prevSireId,
            };
            this.selectedPuppyId = this.dogInfo.puppyId;
          });
      } else {
        this.dogInfo = {
          dogId: "",
          owners: [] as any,
          puppyId: null,
          registrationDate: new Date().toISOString(),
        };
        this.selectedPuppyId = null;
        this.isEditMode = true;
      }
      lookupsService.getDogsAttributes().then((res) => {
        this.colors = res;
        this.showValidationDialog = false;
        this.validationResult = { errors: [], warnings: []};
        this.errorMessage = "";
      });
      
      this.puppyRefreshChanges = [];
    },
    toggleEditMode: function () {
      if (this.isEditMode) {
        this.validateAndConfirmSavingChanges();
      } else {
        this.isEditMode = true;
      }
    },
    updateDates: function (val: any, key: string) {
      this.dogInfo[key] = val.date;
      this.dogInfo = Object.assign({}, this.dogInfo);
    },
    updateSelectedPeople: function (val: any) {
      //workaround for setting up the primary owner
      //and issues with reactivity in Vue https://v2.vuejs.org/v2/guide/reactivity.html?redirect=true#For-Arrays
      if (
        val.newPeople &&
        (val.newPeople.length === 1 ||
          (val.newPeople.length > 0 &&
            val.newPeople.findIndex((own: any) => own.isPrimary) == -1))
      ) {
        val.newPeople[0].isPrimary = true;
      }
      if (!!this.dogInfo.owners && val.newPeople && val.newPeople.length > 0) {
        this.dogInfo.owners.splice(val.newPeople.length);
      }
      this.$set(this.dogInfo, "owners", val.newPeople);
    },
    onPuppySelected: function(puppyInfo: any){
      this.showPuppiesSearchDialog = false;
      dogService
        .getPuppyRegistrationInfoById(puppyInfo.id)
        .then((res: any) => {
          this.dogInfo.body = res.body;
          this.dogInfo.sex = res.sex;
          this.dogInfo.litterId = res.litterId;
          this.dogInfo.birthdate = res.birthdate;
          this.dogInfo.damId = res.damId;
          this.dogInfo.sireId = res.sireId;
          this.dogInfo.breed = res.breed;
          this.dogInfo.puppyId = res.puppyId;
          this.puppyInfo.dogId = res.dogId;
          this.dogInfo.registrationType = "E";
          this.persistedDogInfo = {
              damId: res.damId,
              sireId: res.sireId
            };
        });
      
      this.puppyRefreshChanges = [];
    },
    clearPuppySelection: function() {
      this.showPuppiesSearchDialog = false;
      this.dogInfo.litterId = null;
      this.dogInfo.puppyId = null;
      this.dogInfo.body = null;
      this.dogInfo.sex = null;
      this.dogInfo.birthdate = null;
      this.dogInfo.damId = null;
      this.dogInfo.sireId = null;
      this.dogInfo.breed = null;
      this.puppyInfo.dogId = null;
      this.dogInfo.registrationType = null;
      this.persistedDogInfo = {
        damId: null,
        sireId: null,
        AdminDogSearchDialog: null,
      };

      this.puppyRefreshChanges = [];
    },
    refreshPuppyData: function() {
      dogService
        .getPuppyRegistrationInfoById(this.dogInfo.puppyId)
        .then((res: any) => {

          let warnings = [] as string[];  
          if(this.dogInfo.body != res.body){
            warnings.push(`Main color changed from '${this.dogInfo.body}' to '${res.body}'`);
          }
          if(this.dogInfo.sex != res.sex){
            warnings.push(`Gender changed from '${this.dogInfo.sex}' to '${res.sex}'`);
          }
          if(this.dogInfo.litterId != res.litterId){
            warnings.push(`Litter ID# from '${this.dogInfo.litterId}' to '${res.litterId}'`);
          }
          if(this.dogInfo.birthdate != res.birthdate){
            warnings.push(`Birth date changed from '${this.dogInfo.birthdate}' to '${res.birthdate}'`);
          }
          if(this.dogInfo.damId != res.damId?.trim()){
            warnings.push(`Dam ID# changed from '${this.dogInfo.damId}' to '${res.damId?.trim()}'`);
          }
          if(this.dogInfo.sireId != res.sireId?.trim()){
            warnings.push(`Sire ID# changed from '${this.dogInfo.sireId}' to '${res.sireId?.trim()}'`);
          }
          if(this.dogInfo.breed != res.breed){
            warnings.push(`Breed from '${this.dogInfo.breed}' to '${res.breed}'`);
          }
          if(this.dogInfo.puppyId != res.puppyId){
            warnings.push(`Puppy ID# from '${this.dogInfo.puppyId}' to '${res.puppyId}'`);
          }
          if(this.dogInfo.dogId != res.dogId){
            warnings.push(`Dog ID# from '${this.dogInfo.dogId}' to '${res.dogId}'`);
          }
          if(this.dogInfo.registrationType != 'E'){
            warnings.push(`Registration type from '${this.dogInfo.registrationType}' to 'E'`);
          }

          this.dogInfo.body = res.body;
          this.dogInfo.sex = res.sex;
          this.dogInfo.litterId = res.litterId;
          this.dogInfo.birthdate = res.birthdate;
          this.dogInfo.damId = res.damId?.trim();
          this.dogInfo.sireId = res.sireId?.trim();
          this.dogInfo.breed = res.breed;
          this.dogInfo.puppyId = res.puppyId;
          this.puppyInfo.dogId = res.dogId;
          this.dogInfo.registrationType = "E";
          this.persistedDogInfo = {
              damId: res.damId,
              sireId: res.sireId
            };

          if(warnings.length > 0) {
            this.puppyRefreshChanges = warnings;
            this.showPuppyRefreshChanges = true;
          }
        });
    },
    generateDogId: function () {
      this.$store.dispatch("changeLoaderStatus", true);
      dogService
        .generateDogId({ registrationType: this.dogInfo.registrationType })
        .then((res: any) => {
          this.dogInfo.dogId = res.id;
          if (res.success && res.id) {
            this.dogInfo.dogId = res.id;
            this.dogInfo.isRevoked = false;
            this.dogInfo.notForBreeding = this.dogInfo.registrationType === 'LEP' ? true : false;
            this.errorMessage = "";
          } else {
            this.errorMessage =
              "Couldn't generate new ID." + res.messages.join(", ");
            this.showErrorMessage = true;
          }
        })
        .catch((e) => {
            this.errorMessage ="Couldn't generate new ID.";
            this.showErrorMessage = true;
        })
        .finally(() => this.$store.dispatch("changeLoaderStatus", false));
    },
    validateAndConfirmSavingChanges: function () {
      var currentDogId = this.dogId ? this.dogId : "";
      this.dogInfo.currentDogId = currentDogId;
      dogService
        .validateDogRegistrationInfoEdit(this.dogInfo)
        .then((res: any) => {
          this.validationResult = res;
          if (res.hasValidationIssues) {
            this.showValidationDialog = true;
          } else {
            this.saveChanges();
          }
        });
    },
    saveChanges: function () {
      this.showValidationDialog = false;
      this.$store.dispatch("changeLoaderStatus", true);
      dogService
        .editDogById(this.dogInfo.currentDogId, this.dogInfo)
        .then((res) => {
          console.log(res)
          if (res.success) {
            if (res.id && res.id?.trim() != this.dogId?.trim()) {
              this.$router.push({ path: `/admin/dogs/${encodeURIComponent(res.id.trim())}` })
            }            
            this.reloadData();
            this.showInfoMessage = true;
            this.infoMessage = Messages.SaveSuccessful;
          } else {
            this.errorMessage = res.messages.join(", ");
          }
        })
        .catch((e) => {
          this.errorMessage = Messages.SaveError;
          this.showErrorMessage = true;          
        })
        .finally(() => this.$store.dispatch("changeLoaderStatus", false));
    },    
    changeSire: function() {
      const oldSireId = this.persistedDogInfo.sireId;
      const newSireId = this.newSireId;

      const notes = `Sire changed from ${oldSireId} to ${newSireId}.`;

      this.dogInfo.sireId = newSireId;
      this.dogInfo.prevSireId = oldSireId;
      this.dogInfo.adminNotes = !!this.dogInfo.adminNotes ? this.dogInfo.adminNotes + " | " + notes : notes;

      this.validateAndConfirmSavingChanges();

      this.isChangingSire = false;
      this.newSireId = "";
    },
  },
  watch: {
    selectedPuppyId: function (newVal: String) {
      if (!newVal || this.dogInfo.puppyId != newVal) {
        //if puppy id cleared, clear all related data
        this.dogInfo.litterId = null;
        this.dogInfo.body = null;
        this.dogInfo.sex = null;
        this.dogInfo.birthdate = null;
        this.dogInfo.puppyId = null;
        this.puppyInfo.dogId = null;
      }
    },
    "dogInfo.registrationType": function (newVal: String, oldVal: String) {
      if (newVal && this.ascaDogsRegistrations.includes(newVal?.trim())) {
        this.dogInfo.ascaRegistered = true;
        this.dogInfo.breed = "AS ";

        if (this.isEditMode && newVal?.trim() === "LEP") this.dogInfo.notForBreeding = true;
      } else if (oldVal) {
        //only update if there was previous value selected, otherwise leave values that were persisted
        this.dogInfo.ascaRegistered = false;
        this.dogInfo.breed = null;
      }
      if (newVal && oldVal) {
        this.dogInfo.isRevoked = false;
      }
    },
    "dogInfo.isRevoked": function (newVal: Boolean, oldVal: Boolean) {
      if (
        this.dogInfo.registrationType &&
        this.revokableRegistrationTypes.includes(this.dogInfo.registrationType)
      ) {
        if (newVal) {
          this.dogInfo.dogId = this.dogInfo.dogId.replace(
            this.dogInfo.registrationType,
            "R"
          );
          this.dogInfo.dogId = this.dogInfo.dogId.replace("N", "R");
        } else {
          if (this.dogInfo.notForBreeding) {
            this.dogInfo.dogId = this.dogInfo.dogId.replace("R", "N");
          } else {
            this.dogInfo.dogId = this.dogInfo.dogId.replace(
              "R",
              this.dogInfo.registrationType
            );
          }
        }
      }
    },
    "dogInfo.notForBreeding": function (newVal: Boolean, oldVal: Boolean) {
      if (
        this.dogInfo.registrationType &&
        this.revokableRegistrationTypes.includes(
          this.dogInfo.registrationType.trim()
        )
      ) {
        if (newVal) {
          if (!this.dogInfo.isRevoked) {
            var firstLetter = this.dogInfo.dogId[0];
            this.dogInfo.dogId = this.dogInfo.dogId.replace(firstLetter, "N");
          }
        } else {
          if (this.dogInfo.isRevoked) {
            this.dogInfo.dogId = this.dogInfo.dogId.replace("N", "R");
          } else {
            this.dogInfo.dogId = this.dogInfo.dogId.replace(
              "N",
              this.dogInfo.registrationType[0]
            );
          }
        }
      }
    },
    dogId: function (newVal: String) {
      this.reloadData();
    },
  },
});
