
import Vue from 'vue';
import AussieTimeRegistration from './Steps/AussieTimeRegistrationRenewal.vue';

import { UserInfo } from "@/types/User";
import memberService from '@/services/portal/memberService';

const createAccountImage = require('@/assets/create-account.png');

export default Vue.extend({
  components: { AussieTimeRegistration },
  name: 'AussieTimesRenewal',
  mounted: function() {
    const userInfo = this.$store.state.userInfo;
    if (!!userInfo) this.initialize();
  },
  data: function () {
    return {
      createAccountImage
    };
  },
  watch: {
    userInfo: function(newValue) {
      if (!!newValue) {
        this.initialize();
      }
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    initialize: async function () {
      const userInfo = this.userInfo;

      const primary = {
        memberId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "UNITED STATES",
        address: "",
        international: "",
        city: "",
        stateProvince: "",
        zipPostal: "",
      };

      let plan;
      let aussieTimeFees = null;

      if (!!userInfo) {
        primary.memberId = userInfo.memberId;
        primary.firstName = userInfo.firstName;
        primary.lastName = userInfo.lastName;
        primary.email = userInfo.email;
        primary.phone = userInfo.phone;
        primary.country = userInfo.country;
        primary.address = userInfo.address;
        primary.international = userInfo.international;
        primary.city = userInfo.city;
        primary.stateProvince = userInfo.stateProvince;
        primary.zipPostal = userInfo.zipPostal;

        const fees = await memberService.getMemberFees();

        if (!!fees) {
          plan = fees.memberships.find(p => p.memberType === userInfo.memberType!);
          aussieTimeFees = {
            surfacePostageCanada: fees.surfacePostageCanada,
            surfacePostageOther: fees.surfacePostageOther,
            firstClassUsa: fees.firstClassUsa,
            firstClassCanada: fees.firstClassCanada,
            firstClassOther: fees.firstClassOther,
          };
        }
      }

      this.$store.commit('changeRegisterRenewMembershipInfo',
      {
        isAussieRenewal: true,
        plan,
        aussieTimeFees,
        primary: { ...primary },
      });
    }
  }
});
