
import Vue from "vue";
import { debounce } from "throttle-debounce";
import registerLitterService from "@/services/portal/registerLitterService";

export default Vue.extend({
  name: "AddSireModal",
  data: function () {
    return {
      showDialog: this.sireDialog,
      hasSearched: false,
      searchResults: [] as object[],
      errors: [],
      selectedSires: [...this.sireInfo],
      dogID: "",
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Color", align: "left", value: "mainColor" },
        {
          text: "Dog ID",
          align: "left",
          value: "dogId",
        },
      ],
    };
  },
  watch: {
    sireDialog: function (newVal: boolean) {
      if (!newVal) {
        this.dogID = "";
        this.hasSearched = false;
        this.searchResults = [];
      }
      this.showDialog = newVal;
    },
    showDialog: function (newVal: boolean) {
      //this is handling situation when the user closes dialog
      //by clicking outside of it
      if (!newVal) {
        this.dogID = "";
        this.hasSearched = false;
        this.searchResults = [];
        this.selectedSires = [...this.sireInfo];
        this.toggleSireModal();
      }
    },
    dogID: {
      handler: debounce(
        1000,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal || !newVal) return;
          this.search();
        }
      ),
      deep: true,
    },
  },
  methods: {
    search: function () {
      this.hasSearched = false;
      registerLitterService.searchSire(this.dogID).then((response: any) => {
        this.hasSearched = true;
        this.searchResults = response.data ? [response.data] : [];
        this.errors = response.errors ? response.errors : [];
      });
    },
    addSire(row: any) {
      const found = this.selectedSires.some((el: any) => el.dogId === row.dogId);
      if (found) return;

      this.searchResults = [];
      this.hasSearched = false;
      this.dogID = "";

      const owners = [] as any;
      row.primaryOwners.forEach((o: any) => owners.push({PersonId: o.personId, IsPrimary: o.isPrimary}))
      let item = {
        name: row.name,
        dogId: row.dogId,
        mainColor: row.mainColor,
        owners: owners,
        dnaSubmitted: row.dnaSubmitted,
      };

      this.selectedSires.push(item);
    },
    removeSire(row: any) {
      this.selectedSires.splice(this.selectedSires.indexOf(row), 1);
      this.changeSireInfo(this.selectedSires);
      this.changePuppyInfo([], row.dogId, true);
    },
    submit() {
      this.changeSireInfo(this.selectedSires);
      this.showDialog = false;
    },
  },
  props: {
    changeSireInfo: Function,
    sireDialog: Boolean,
    toggleSireModal: Function,
    sireInfo: Array,
    changePuppyInfo: Function,
  },
});
