import { api } from "./api";
import { PedigreeTypes } from "@/constants";

export const getDogsAttributes = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Lookups/dog`);
    return data;
}

export const getPuppyAttributes = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Lookups/puppy`);
    return data;
}

export const getDnaStatuses = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Lookups/dnastatuses`);
    return data;
}

export const getPedigreePrintTypesList = function(){
    return [
        { name: "Digital Only", id: PedigreeTypes.Digital, printable: false },
        { name: "Small", id: PedigreeTypes.Small, printable: true },
        { name: "Large", id: PedigreeTypes.Large, printable: true },
      ];
};

export default {
    getDogsAttributes,
    getPuppyAttributes,
    getDnaStatuses,
    getPedigreePrintTypesList,
}