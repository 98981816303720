
import Vue from "vue";
import personService from "@/services/portal/personService";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "MyPayments",
  data: function() {
    return {
      payments: Array<object>(),
      headers: [
        { text: "#ID", align: "left", value: "id", width: "50px" },
        { text: "Registration type", align: "left", value: "workflowTypeDesc", width: "160px" },
        { text: "Fee amount", align: "left", value: "transactionAmount", width: "50px" },
        { text: "Requested at", align: "left", value: "requestedAt", width: "100px" },
        { text: "Paid at", align: "left", value: "paidAt", width: "100px" },
        { text: "Payment type", align: "left", value: "paymentTypeDesc", width: "100px" },
        { text: "", align: "left", value: "actionButton", width: "200px" },
      ],
      workflowsWithDetailsAndPaymentView: [
        "KennelRegistration",
        "LitterRegistration",
        //PuppyRelease = 3,
        "LeaseRegistration",
        "AffiliateRegistration",
        "AffiliateRenewal",
        "KennelOwnersChange",
        "QTRegistration",
        // LEPRegistration = 9,
        // MemberRegistration = 10,
        // MemberRenewal = 11,
        // MemberAussieRenewal = 12,
        // PedigreeOrder = 13,
      ]
    };
  },
  created: function () {
    if(this.userInfo){
      personService
        .getMyPayments(this.userInfo.personId)
        .then((res) => {
          this.payments = res;
        }).catch((e) => console.log(e));
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  watch: {      
    userInfo: function (newVal: object) { 
      personService
        .getMyPayments(this.userInfo.personId)
        .then((res) => {
          this.payments = res;
        }).catch((e) => console.log(e));
    },
  },
});
