
import Vue from "vue";
import ReportViewer from '../../../Admin/Reports/ReportViewer.vue';

import lookupsService from "@/services/lookupsService";
import litterService from "@/services/admin/litterService";

import { UserInfo } from "@/types/User";
import { Messages } from "@/constants";

export default Vue.extend({
  name: "LitterPuppies",
  components: {
    ReportViewer,
  },
  data: function() {
    return {
      litterInfo: {
        litterId: null, 
        owners: [] as any,
        kennels: [] as any,
        puppies: [] as any,
        whelpingDate: null,
        whelpingDateFormatted: '',
        dam: {
          id: null
        },
        sire: {
          id: null
        },
        adminNotes: '',
        notes: ''
      } as any,
      isEditMode: false,
      headers: [
        { text: "Puppy ID", value: "id" },
        { text: "Sex", value: "gender" },
        { text: "Main Color", value: "mainColor" },
        { text: "Is Dead/Culled", value: "isDead" },
        { text: "Dog ID", value: "dogId" },
        { text: "Breeder Notes", value: "notes" },
        { text: "Released", value: "released" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedPuppyId: null,
      Report_Name: 'Asca.Api.Reports.rpt2710_Puppy_Registration_Form.rdlx',
      Report_URL: '`/Admin/reports/litter/registration/${this.selectedPuppyId}`',
      showFormDialog: false,
      showValidationDialog: false, 
      validationResult: {
        warnings: [],
        errors: []
      },     
      genders: [] as any[],
      mainColors: [] as any[],
      errorMessage: '',
      infoMessage: '',
      showErrorMessage: false,
      showInfoMessage: false
    };
  },
  methods: {
    showPuppyRegistrationFormDialog: function (){   
      this.Report_Name = "Asca.Api.Reports.rpt2710_Puppy_Registration_Form.rdlx";
      this.Report_URL = "/Admin/reports/litter/registration/"+this.selectedPuppyId;
      this.showFormDialog = true;
    },
    showLitterRegistrationFormDialog: function (){
      this.Report_Name = "Asca.Api.Reports.rpt2710_Puppy_Registration_Form.rdlx";
      this.Report_URL = "/Admin/reports/litter/"+this.litterInfo.litterId+"/registration";
      this.showFormDialog = true;
    },
    reloadData: function() {
      lookupsService.getPuppyAttributes().then((res: any) => {
        this.genders = res.genders;
        this.mainColors = res.mainColors;
      });
      if (this.litterId) {
        litterService
          .getLitterById(this.litterId)
          .then((res) => {
            this.litterInfo = res;
            this.litterInfo.puppies = [...res.puppies, {isNewRow: true}];
            this.errorMessage = '';
          });
      }
    }, 
    deletePuppy(item: any) {
      const index = this.litterInfo.puppies.indexOf(item);
      this.litterInfo.puppies.splice(index, 1);
    },
    savePuppiesList: function () {
      this.$store.dispatch("changeLoaderStatus",true);
      //remove empty record
      let mappedPuppies = this.litterInfo.puppies.map((p: any) => ({
        id: p.id ? p.id : null,
        mainColor: p.mainColor,
        isDead: p.isDead ? p.isDead : null,
        gender: p.gender
      }));
      mappedPuppies.splice(mappedPuppies.length - 1, 1);
      litterService
                .editLitterPuppies(this.litterInfo.litterId, mappedPuppies)
                .then((res: any) => {
                  if(res.success){                   
                    this.reloadData();
                    this.infoMessage = Messages.SaveSuccessful;
                    this.showInfoMessage = true;
                  } else {              
                    this.errorMessage =  Messages.SaveError + " " + res.messages.join(', ');
                    this.showErrorMessage = true;
                  }
                })
                .catch((e: any) => {
                    this.errorMessage =  Messages.SaveError;
                    this.showErrorMessage = true;                  
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus",false));
    }
  },
  created: function() {
    this.reloadData();
  },
  watch: {
    litterId: function(newVal: String) {
      this.reloadData();
    },
    lastRow: function(newVal, oldVal){
        const isNewRowAdded = Object.keys(newVal).length > 1;
        if(isNewRowAdded){          
          this.litterInfo.puppies = [...this.litterInfo.puppies, {}];
          this.litterInfo.puppies[this.litterInfo.puppies.length - 2].isNewRow = false;
          this.litterInfo.puppies[this.litterInfo.puppies.length - 1].isNewRow = true;
        }
    },
  },
  props: {
    litterId: { type: Number },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    lastRow(): Object {
        return this.litterInfo.puppies[this.litterInfo.puppies.length - 1];
    }
  },
});
