
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import MyRequestLayout from "@/components/Portal/MyRequest/MyRequestLayout.vue";
import QTDogRegistrationRequest from "@/components/Portal/MyRequest/QTDogRegistrationRequest.vue";

import workflowService from "@/services/portal/workflowService";

import { UserInfo } from "@/types/User";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";

export default Vue.extend({
  name: "MyQTDogRequest",
  components: {
    MyRequestLayout,
    CoownersList,
    QTDogRegistrationRequest,
  },
  data: function () {
    return {
      registration: Object as any,
      isLoading: false,
      paymentStatus: null,
      errorMessage: "",
      showErrorMessage: false,
    };
  },
  props: {
    workflowGuid: { type: String },
  },
  methods: {
    load() {
      this.isLoading = true;
      workflowService
        .getQTDogRequestDetails(this.workflowGuid)
        .then((res) => {
          this.isLoading = false;
          this.registration = res;
          console.log(res);
        });
    },
    makePayment: function (paymentInfo: AuthorizeNetResponseData | null) {
      var req = {
        paymentInfo,
        personId: this.userInfo.personId,
        workflowGuid: this.registration.workflowGuid,
      };

      workflowService.submitDogCCPayment(req).then((res) => {
        if (res.error) {
          this.errorMessage = res.error;
          this.showErrorMessage = true;
        }
        else {
          this.paymentStatus = res;
        }

        this.load();
      });
    },
    cancelRequest: function () {
      console.log("cancelling request");
    },
  },
  created() {
    this.load();
  },
  watch: {
    workflowGuid: function (newValue) {
      this.load();
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    dogOwnershipType: function () {
      return OwnershipType.Dog;
    },
  },
});
