import { api } from "../api";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const listMyPedigrees = async (
  personId: number,
  options: PaginationOptions,
  params: object,
): Promise<PaginatedResponse<object>> => {  
  const { page, itemsPerPage, sortBy, sortDesc } = options;

  const sort = !!sortBy.length ? sortBy[0] : 'id';
  const asce = !!sortDesc.length ? !sortDesc[0] : false;

  const { data } = await api.get<PaginatedResponse<object>>(`/pedigree/${personId}/pedigrees?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

  return data;
};

export const orderPedigree = async (dto: any): Promise<any> => {
  try {
    const { data } = await api.post<any>('/pedigree/order', dto);
    return { ...data };
  }  catch (err) {
      if ((err as any).data) {
        return {...(err as any).data, success: false};
      } else {
        return { errors: ["An Unknown Error Occured"], success: false };
      }
  }
}

export const getFees = async (): Promise<any> => {
  try {
    const { data } = await api.get<any>('/pedigree/fees');
    return {...data, success: true};
  }  catch (err) {
      if ((err as any).data) {
        return {...(err as any).data, success: false};
      } else {
        return { errors: ["An Unknown Error Occured"], success: false };
      }
  }
}

export default {
  listMyPedigrees,
  orderPedigree,
  getFees,
};
