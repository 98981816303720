import { api } from "../api";

import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";


export const getPedigreeOrdersList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy?.length ? sortBy[0] : 'id';
    const asce = !!sortDesc?.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/pedigrees?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const markPedigreeOrdersPrinted = async (orderIds: number[]): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/pedigrees/markPrinted?ids=${orderIds.join('&ids=')}`);

    return data;
};

export const registerPedigreeOrder = async (request: object): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/pedigrees/order`,  request);

    return data;
};

export default {
    getPedigreeOrdersList,
    markPedigreeOrdersPrinted,
    registerPedigreeOrder,
};