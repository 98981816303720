
import Vue from "vue";
import { UserInfo } from "@/types/User";
import codeVerificationService, { VerificationType } from "@/services/codeVerificationService";

export default Vue.extend({
  name: "VerifyCodeDialog",
  props: {
    buttonText: {type: String},
    showDialog: {type: Boolean},
    verificationType: {type: Number},
    contactInfo: {type: String},
    onSuccess: {type: Function},
    onClose: {type: Function},
  },
  data: function() {
    return {
      text: this.buttonText ?? "Verify",
      errorMessage: "",
      infoMessage: "",
      showLoader: false,
      verificationGuid: "",
      verificationGuids: [],
      verificationCode: "",
    };
  },
  watch: {
    "showDialog": function(newVal, oldVal){
      if (newVal === oldVal) return;

      if (newVal === true) {
        this.verificationCode = "";
        this.sendVerificationCode();
      }
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    verificationTypeTimeoutDescription(): String {
      switch(this.verificationType) {
        case VerificationType.Email:
        case VerificationType.PhoneNumber:
        default:
          return "30 minutes";
      }
    },
    verificationTypeDescription(): String {
      switch(this.verificationType) {
        case VerificationType.Email:
          return "the email address provided.";
        case VerificationType.PhoneNumber:
          return "the phone number provided.";
        default:
          return "";
      }
    },
    sendCodeFun(): Function {
      switch(this.verificationType) {
        case VerificationType.Email:
          return codeVerificationService.startEmailVerification;
        case VerificationType.PhoneNumber:
          return codeVerificationService.startPhoneNumberVerification;
        default:
          return () => {};
      }
    },
  }, 
  methods: {
    sendVerificationCode: function() {   
      this.sendCodeFun(this.contactInfo)
        .then((res: any) => {
          if (res.success) {
            this.verificationGuid = res.instanceId;
            this.verificationGuids = res.instanceIds;
            this.verificationCode = "";
            this.errorMessage = "";
            this.infoMessage = res.infoMessage ?? "";
          } else if (res.errors) {
            this.errorMessage = res.errors.join(",");
          }
        })
        .catch((e: any) => console.log(e));
    },
    checkCode: async function() {
      this.showLoader = true;

      if (!this.verificationGuid && !this.verificationGuids.length) 
      {
        this.showLoader = false;
        this.errorMessage = "Start verification failed";
      }

      if (!!this.verificationGuid || this.verificationGuids.length === 1) {
        const guid = this.verificationGuid ?? this.verificationGuids[0];

        const response = await codeVerificationService.postVerificationCodeForChallengeType(guid, this.verificationCode, this.verificationType);

        if (response.CodeCorrect) {
          this.onSuccess();
          this.errorMessage = "";
          this.verificationCode = "";
          this.verificationGuid = "";
          this.verificationGuids = [];
        } else this.errorMessage = response.ErrorMessage;

        this.showLoader = false;
      } else {
        let success = false;
        let errorMessage = "";

        for (const guid of this.verificationGuids) {
          try {
            const response = await codeVerificationService.postVerificationCodeForChallengeType(guid, this.verificationCode, this.verificationType);

            if (response.CodeCorrect == true) {
              success = true;
              break; // No further checks are needed;
            } else errorMessage = response.ErrorMessage;
          } catch (ex) {
            console.log(ex);
          }
        }
        
        if (success) {
          this.onSuccess();
          this.errorMessage = "";
          this.verificationCode = "";
          this.verificationGuid = "";
          this.verificationGuids = [];
        } else this.errorMessage = errorMessage;

        this.showLoader = false;
      }
    },
  },
});
